.full_width_image {
  position: relative;
  width: 100%;
  height: 100vh;

  &__item {
    width:100%;
    height:100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;
  }
  
  
}