@import "../../styles/global.scss";

.preloader {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  display: flex;
  
  &__blind {
    cursor: wait;
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: $dark-grey;

    &:nth-child(2) {
      right: 0;
    }
  }
}