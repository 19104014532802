.text_split {
  position: relative;
  display: flex;
  justify-content: center;
  
  &__mask {
    position: relative;
    overflow: hidden;
    display: inline-flex;
  }
}
