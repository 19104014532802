$green-primary: #5dff63;
$green-secondary: #71BF4D;
$black: #100f0f;
$white: #fff;
$cream: #e8c083;
$grey: #adc2c5;
$dark-grey: #2f2f2f;
$light-cream: #f2eee9;
$orange: #fa7a1f;
$golden: linear-gradient(338deg, #FADA68 6.94%, rgb(250 218 104) 102.43%, rgb(250 218 104) 178.02%);
$shadow: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0));

/* -- Media Query Breakpoints -- */
$phone: 320;
$phablet: 556;
$tablet: 768;
$tablet-large: 1024;
$laptop: 1199;
$desktop: 1440;
