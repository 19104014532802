@import "./utils/typography";
@import "./utils/fonts";
@import "./utils/mixins";
@import "./utils/variables";

body {
  color: $white;
  @extend %body-text;
  -ms-overflow-style: none;
  scrollbar-width: none;  
  background-color: $white;
}

::-webkit-scrollbar {
  display: none;
}

.container {
  max-width: 90vw;
  margin: 0 auto;
}

.cross {
  position: absolute;
  top: 7%;
  left: 4%;
  width: 3vw;
  height: 3vw;
  background: url("../images/home/cross.svg") no-repeat center;
  background-position: 0;
  background-size: 80%;
  transition: all 0.3s ease;
  mix-blend-mode: exclusion;

  @include mq() {
    width: 7vw;
    height: 7vw;
  }

  &:hover {
    opacity: 0.7;
  }
}