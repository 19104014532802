@font-face {
  font-family: "Din";
  src: url("../../../public/fonts/d-din/D-DIN.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Din";
  src: url("../../../public/fonts/d-din/D-DIN-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Din";
  src: url("../../../public/fonts/d-din/D-DIN-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
