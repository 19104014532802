@import "../../styles/global.scss";

.slide-in {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0%;
  z-index: 100;
  background-color: $dark-grey;
  cursor: wait;
}