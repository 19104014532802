@import "../../../styles/global.scss";

.about_game {
  background-color: $black;
  padding: 4vw 0;
  
  &__container {
    padding: 4vw 0;
    border-top: 0.2vw solid white;
    border-bottom: 0.2vw solid white;
    
  }

  .main_info {
    padding-bottom: 4vw;
    border-bottom: 0.2vw solid white;
    

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2vw;
    }
  
    &__partners {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50vw;
  
      &_item {
        display: flex;
        gap: 0.5vw;
      }
  
      &_title {
        max-width: 50vw;
      }
    }
  
    .chat-gpt {
      width: 3vw;
    }
  
    &__bottom {
      margin: 2.5vw 0;
      display: flex;
      gap: 5vw;

      @include mq() {
        flex-direction: column;
      }
    }
    
    &__text {
      width: 50%;

      @include mq() {
        width: 100%;
      }
    }
  
    &__post_script {
      color: $grey;
      text-align: center;
    }
  }

  .info_game {
    text-align: center;
    padding: 4vw 10vw;
    border-top: 0.2vw solid white;
    border-bottom: 0.2vw solid white;
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }

  .game_features {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding-top: 4vw;

    @include mq() {
      flex-direction: column;
      align-items: center;
      gap: 5vw;
    }

    &__item {
      width: 25vw;
      display: flex;
      flex-direction: column;
      gap: 1vw;

      @include mq() {
        width: 100%;
        gap: 4vw;
      }
    }

    &__image {
      width: 100%;
      height: 20vw;
      object-fit: cover;

      @include mq() {
        width: 100%;
        height: 40%;
      }
    }
  }
}
