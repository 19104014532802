@import "../../../styles/global.scss";

.contacts_head {
  position: relative;
  @include mq() {
    height: 90vh;
  }

  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    text-align: center;
    font-weight: bold;
    line-height: 85.6%;
    font-style: italic;

    .bigger {
      font-size: 6vw;
    }
  }

  &__link {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%);
    transition: all .3s ease;
    
    &:hover {
      color: $cream;
      mix-blend-mode: difference;
    }
  }
}