@import "../../../styles/global.scss";

.socials {
  font-family: Din, sans-serif;
  background-color: $black;
  position: relative;
  transform: translate(-50%, -50%);

  &__title {
    text-align: center;
    padding-top: 0.6vw;

    @include mq($tablet-large) {
      padding-top: 4vw;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 2.3vw;
    padding-bottom: 2.3vw;
    border-bottom: 0.2vw solid $white;

    @include mq() {
      flex-direction: column;
      align-items: stretch;
      gap: 3vw;
      text-align: center;
    }
  }

  &__link {
    background-color: $white;
    color: $black;
    padding: 1vw 5vw;
    line-height: 4vw;
    font-weight: bold;

    transition: all 0.3s ease;

    &:hover {
      color: $white;
    }

    @include mq() {
      line-height: 8vw;
    }
  }

  &__text {
    background: linear-gradient(90deg, rgba(81,160,152,1) 0%, rgba(9,121,82,1) 63%, rgba(73,141,78,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 2.3vw;

    &_top {
      display: flex;
      gap: 2vw;
      margin-bottom: 1.5vw;
      max-width: 90%;
      margin: 0 auto 2vw;

      @include mq($tablet-large) {
        display: none;
      }
    }

    &_bottom {
      text-align: center;
      padding-bottom: 3vw;

      @include mq() {
        display: flex;
        flex-direction: column;
        gap: 3vw;
        padding-bottom: 5vw;
      }
    }
  }
}