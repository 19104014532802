@import "../../../styles/global.scss";

.navigation {
  position: relative;
  left: 6vw;
  
  &__menu {
    background-color:wheat;
    position: absolute;
    z-index: 20;
  }

  &__button {
    width: 10vw;
    height: 4vw;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    z-index: 21;

    @extend %link;

    @include mq() {
      width: 14vw;
      height: 10vw;
    }

    &_slider {
      position: relative;
      width: 100%;
      height: 100%;

      .el {
        width: 100%;
        height: 100%;
        background-color: $black;
        font-weight: bolder;
        position: relative;

        &:first-child {
          p {
            margin: 0px;
            color: red;
          }

          @include hoverForLinks($white);
        }

        &:nth-child(2) {
          background-color: red;
          p {
            margin: 0px;
            color: $black;
          }

          @include hoverForLinks($black);
        }

        .perspectiveText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
          transform-style: preserve-3d;
          transition: transform 0.75s cubic-bezier(0.76, 0, 0.24, 1);

          p {
            transition: all 0.75s cubic-bezier(0.76, 0, 0.24, 1);
            pointer-events: none;
            text-transform: uppercase;

            &:nth-of-type(2) {
              position: absolute;
              transform-origin: bottom center;
              transform: rotateX(-90deg) translateY(9px);
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
